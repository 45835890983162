import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useStytchSession } from '@stytch/nextjs';

import LoadingScreen from 'components/container-components/LoadingScreen';
import withLayout from 'components/container-components/layout/withLayout';
import { AuthRoutes, CommonRoutes } from 'routes';

const Home: NextPage = () => {
  const router = useRouter();
  const { session } = useStytchSession();

  useEffect(() => {
    // if logged in, redirect to dashboard
    if (session) {
      router.push(CommonRoutes.Dashboard);
    } else {
      router.push(AuthRoutes.SignIn);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return (
    <main className="h-screen">
      <LoadingScreen />
    </main>
  );
};

export default withLayout(Home);
